export const categoriesOrder = [
    "soda",
    "non-carbonated beverages",
    "snacks, candy",
    "cereal, breakfast",
    "condiments, spices",
    "canned food, sauce",
    "processed fruits & vegetables",
    "grain, pasta, bread",
    "Meat, protein",
    "packaged meals",
    "oils & fats",
    "milk",
    "other dairy"
]
